
<template>
  <div class="exception-body notfound">
    <div class="exception-content">
      <div class="exception-detail"><h2>Merci, votre opération s'est déroulée avec succès.</h2></div>
      <div class="exception-detail"><h3>le CNSCL passe à la vérification du paiement.</h3></div>
      <router-link to="/">Retour à l'accueil</router-link>
    </div>
  </div>
</template>



<script>
import http from '../services/http.service';

export default {
  data() {
    return {
      user: null,
      config: null,
      today: null,
      year: null,
      code: null,
    };
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');

      const current = new Date();
      this.today = `${current.getFullYear()}-${this.digitTonumber(current.getMonth() + 1)}-${this.digitTonumber(
        current.getDate()
      )}`;
      this.code = `${current.getFullYear()}${
        current.getMonth() + 1
      }${current.getDate()}${current.getHours()}${current.getMinutes()}${current.getSeconds()}`;

      this.getProfil();
    } else {
      this.$router.push('/login');
      //Checkout1!
    }
  },
  methods: {
    digitTonumber(digit) {
      let str = digit.toString();
      if (str.length <= 1) {
        str = '0' + str;
      }
      return str;
    },
    async updateRole() {
      const current = new Date();
      this.year = current.getFullYear();
      const data = {
        annee: this.year,
        datePaiement: this.today,
        typePaiement: 'CARTE_BANCAIRE',
        statut: 'EN_COURS',
        numeroCheque: null,
        motifRejet: null,
        dateValidation: this.today,
        referencePaiement: this.code,
        detail: null,
        entrepriseId: this.user.entreprise.id,
      };
      await http
        .post('/abonnements', data, { headers: { Authorization: `Bearer ${this.token}` } })
        .then(async (res) => {
          // console.log('SUCESS ADDING SUBSCRIBER' + res.data.id);
          const url = '/abonnements/' + res.id + '/setAbonnementValidation?paymentStatus=true';
          const payload = {
            statut: 'EN_COURS',
            motifRejet: '',
            roleName: 'USER_VALIDATE',
          };
          await http.post(url, payload, { headers: { Authorization: `Bearer ${this.token}` } });
        })
        .catch((e) => {
          console.log('ERROR ADDING SUBSCRIBERS =>' + e);
        });
    },
    async getProfil() {
      try {
        const res = await http.get('/users/getProfile', { headers: { Authorization: `Bearer ${this.token}` } });

        this.user = res.data;

        this.updateRole();
        //console.log('*******STATUT :   ' + JSON.stringify(this.company.statusEntreprise.fileName));
      } catch (e) {
        console.log('ERROR FROMS 2 => ' + e);
      }
    },
  },
};
</script>